import api from '@/plugins/axios'

const base = 'v1/site/'

export default {
  newsletter (data) {
    return api.post(`${base}registrar-newsletter`, data)
  },
  contact (data) {
    return api.post(`${base}registrar-contato`, data)
  }
}
